import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const Timed = () => (
	<>
		<Helmet>
			<title>404 Not Found</title>
			<meta name="robots" content="noindex, nofollow" />
		</Helmet>
		<div>404 Not Found</div>
	</>
);

export default Timed;

/* Query page data using the pageSections GraphQL fragment included in the imported queryTimedSection file */
export const pageQuery = graphql`
	query GetTimedLayoutPageData($id: String!) {
		contentfulTimedComponent(id: { eq: $id }) {
			sys {
				contentType {
					sys {
						id
					}
				}
			}
			id
			content {
				startAt
				endAt
				components {
					__typename
					...Collection
					...ComponentBlock
					...HardCodedComponent
					...Link
					...ListLink
					...MultiImageBlock
					...Paragraph
					...PromotionRule
					...Section
					...SplitContentWrapper
					...TabsAndAccordion
					...TimedComponent
					...VideoPlayer
					...ResponsiveBanner
				}
			}
			placeHolder {
				__typename
				...Collection
				...ComponentBlock
				...HardCodedComponent
				...Link
				...ListLink
				...MultiImageBlock
				...Paragraph
				...PromotionRule
				...Section
				...SplitContentWrapper
				...TabsAndAccordion
				...TimedComponent
				...VideoPlayer
				...ResponsiveBanner
			}
		}
	}
`;
